
import { defineComponent } from "vue";
import { Alert } from "@/commons/types";
import Alerter from "./Alerter.vue";
import {
  UserSchema,
  UserSetPasswordSchema,
  UsersService,
} from "@/commons/client";

export default defineComponent({
  name: "PasswordChange",

  components: {
    Alerter,
  },

  props: {
    userId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    passwordChangeData: {} as UserSetPasswordSchema,
    confirm: "",
    alerts: [] as Alert[],
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    maxCardWidth() {
      /*************************************
       * Return the right card length based on
       * current breakpoint.
       *************************************/
      switch (this.$vuetify.breakpoint.name) {
        case "xs" || "sm":
          return 400;
      }
      return 550;
    },
    isValidData() {
      return (
        this.passwordChangeData.current_password &&
        this.passwordChangeData.password &&
        this.confirm &&
        this.passwordChangeData.password === this.confirm
      );
    },
  },

  methods: {
    async updatePassword() {
      if (this.isValidData) {
        try {
          const res =
            await UsersService.userChangePasswordV1UsersPkChangePasswordPatch(
              this.userId,
              this.passwordChangeData
            );
          this.alerts.push({
            type: "success",
            text: "Password aggiornata con successo",
          });
        } catch (err) {
          this.alerts.push({
            type: "error",
            text: "Errore durante l'aggiornamento della password",
          });
        }
      } else {
        this.alerts.push({
          type: "error",
          text: "Le password non coincidono, o manca quella attuale",
        });
      }
    },
  },
});
