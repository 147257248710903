
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import {
  OrganizationCreateSchema,
  OrganizationsService,
} from "@/commons/client";

export default Vue.extend({
  name: "OrganizationCreateView",

  components: {
    Alerter,
  },

  data: () => ({
    organizationFormData: {} as OrganizationCreateSchema,
    isUpdating: false,
    alerts: [] as Alert[],
  }),

  computed: {
    rules() {
      /**********************
       * Get rules from store
       **********************/
      return this.$store.state.genericRules;
    },
    countries() {
      return this.$store.state.countries;
    },
    maxCardWidth() {
      /*************************************
       * Return the right card length based on
       * current breakpoint.
       *************************************/
      switch (this.$vuetify.breakpoint.name) {
        case "xs" || "sm":
          return 400;
      }
      return 550;
    },
  },

  methods: {
    async createOrganization(): Promise<void> {
      /*************************************
       * Create a new organization.
       *************************************/
      try {
        const res =
          await OrganizationsService.organizationCreateV1OrganizationsPost(
            this.organizationFormData
          );
        this.$store.commit("setOrganizationId", res.id);
        this.$router.push({ name: "profile" });
      } catch (error) {
        this.alerts.push({
          type: "error",
          text: "Errore durante la creazione dell'azienda",
        });
      }
    },
  },
});
