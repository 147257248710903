
import Vue from "vue";
import { Alert } from "@/commons/types.js";
import { VehicleSchema, VehiclesService } from "@/commons/client";
import CreateVehicleDialog from "@/components/CreateVehicleDialog.vue";
import VehicleDialog from "@/components/VehicleDialog.vue";
import DeleteVehicleDialog from "@/components/DeleteVehicleDialog.vue";

export default Vue.extend({
  name: "VehicleList",

  components: {
    CreateVehicleDialog,
    VehicleDialog,
    DeleteVehicleDialog,
  },

  data: () => ({
    queryData: {
      perPage: 5,
      currentPage: 1,
      organization_id: 1,
    },
    loading: false,
    headers: [
      {
        text: "ID Veicolo",
        align: "start",
        value: "vehicle_id",
      },
      { text: "Targa", value: "plate" },
      //{ text: "Dispositivo", sortable: false, value: "device_id" },
      { text: "Ultimo Giorno", sortable: false, value: "last_day_data" },
      { text: "Actions", sortable: false, value: "actions" },
    ],
    alerts: [] as Alert[],
  }),

  computed: {
    vehicles(): VehicleSchema[] {
      return this.$store.state.vehicles;
    },
    organizationId(): number {
      return this.$store.state.organizationId;
    },
  },

  methods: {
    print: console.log,
    getFreeDeviceHistories() {
      let organization_id = 1;
      let endpoint = `v1/device_histories/?organization_id=${organization_id}&no_vehicle_only=true`;
      console.log("Getting free device histories from " + endpoint);
    },
    async getVehicles(): Promise<VehicleSchema[]> {
      try {
        return await VehiclesService.organizationVehiclesListV1OrganizationsPkVehiclesGet(
          this.organizationId
        );
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore nel recupero dei veicoli",
        });
        return [];
      }
    },
  },
});
