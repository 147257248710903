
import Vue from "vue";

export default Vue.extend({
  name: "NavigationMenu",

  data: () => ({
    links: [
      {
        icon: "mdi-view-dashboard",
        text: "Dashboard",
        route: { name: "home" },
      },
      {
        icon: "mdi-google-maps",
        text: "Mappa",
        route: { name: "map" },
      },
      {
        icon: "mdi-alarm-light",
        text: "Allarmi",
        route: { name: "alarmGraph" },
      },
      {
        icon: "mdi-truck-fast",
        text: "Veicoli",
        route: { name: "vehicleList" },
      },
      {
        icon: "mdi-transit-connection-horizontal",
        text: "Dispositivi",
        route: { name: "deviceHistoryList" },
      },
      {
        icon: "mdi-account",
        text: "Profilo",
        route: { name: "profile" },
      },
    ],
  }),
});
