var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mx-4 my-6"},[_c('v-container',{staticClass:"my-2"},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"text-h6 text-md-h4 text-center"},[_vm._v("I Tuoi Veicoli")])])],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"text-subtitle-1 grey--text text--darken-4 text-center"},[_vm._v(" Inserisci, modifica o elimina i tuoi veicoli. ")]),_c('p',{staticClass:"text-body-1 grey--text text--darken-4 text-center"},[_vm._v(" Inserisci i tuoi veicoli, registrandoli con un ID univoco. Una volta registrati i veicoli, potrai procedere all'associazione con un dispositivo Drive - 1, accedendo all'area "),_c('router-link',{staticClass:"no-decoration grey--text text--darken-4",attrs:{"to":{ name: 'deviceHistoryList' }}},[_c('span',[_vm._v("Dispositivi")])])],1)])],1)],1),_c('v-data-table',{staticClass:"elevation-1 mx-md-6",attrs:{"headers":_vm.headers,"items":_vm.vehicles,"items-per-page":_vm.queryData.perPage,"page":_vm.queryData.currentPage,"loading":_vm.loading,"item-key":"name","footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      prevIcon: 'mdi-minus',
      nextIcon: 'mdi-plus',
    }},scopedSlots:_vm._u([{key:"item.last_day_data",fn:function({ item }){return [_c('router-link',{staticClass:"no-decoration grey--text text--darken-4 no-underline",attrs:{"to":{ name: 'map', query: { vehicleId: `${item.vehicle_id}` } }}},[_vm._v("Vedi ultimo giorno")])]}},{key:"item.actions",fn:function({ item }){return [_c('VehicleDialog',{attrs:{"vehicle":item}}),_c('DeleteVehicleDialog',{attrs:{"vehicle":item}})]}}])}),_c('v-container',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"6"}},[_c('CreateVehicleDialog')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }