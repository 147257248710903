
import Vue from "vue";
import NavigationMenu from "./NavigationMenu.vue";

export default Vue.extend({
  name: "HelloWorld",

  components: {
    NavigationMenu,
  },

  data: () => ({
    // Empty
  }),
});
