
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "./Alerter.vue";
import { VehicleSchema, VehiclesService } from "@/commons/client";

export default Vue.extend({
  name: "DeleteVehicleDialog",

  props: {
    vehicle: {
      type: Object as () => VehicleSchema,
      required: true,
    },
  },

  components: {
    Alerter,
  },

  data: () => ({
    dialog: false,
    alerts: [] as Alert[],
  }),

  computed: {
    organizationId(): number {
      return this.$store.state.organizationId;
    },
    alerterClass(): string {
      return this.alerts.length > 0 ? "mt-2" : "";
    },
  },

  methods: {
    async deleteVehicle(): Promise<void> {
      try {
        await VehiclesService.organizationVehiclesDeleteV1OrganizationsPkVehiclesVehiclePkDelete(
          this.vehicle.organization_id,
          this.vehicle.id
        );
        this.$store.commit("deleteVehicle", this.vehicle.id);
        this.dialog = false;
      } catch (error) {
        this.alerts.push({
          type: "error",
          text: "Errore durante l'eliminazione del veicolo",
        });
      }
    },
  },
});
