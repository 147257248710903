import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import store from "@/store";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import MapView from "../views/MapView.vue";
import ProfileView from "../views/ProfileView.vue";
import VehicleListView from "../views/VehicleListView.vue";
import DeviceHistoryListView from "../views/DeviceHistoryListView.vue";
import AlarmGraphView from "../views/AlarmGraphView.vue";
import OrganizationCreateView from "../views/OrganizationCreateView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/map",
    name: "map",
    component: MapView,
  },
  {
    path: "/vehicles",
    name: "vehicleList",
    component: VehicleListView,
  },
  {
    path: "/devices",
    name: "deviceHistoryList",
    component: DeviceHistoryListView,
  },
  {
    path: "/alarms",
    name: "alarmGraph",
    component: AlarmGraphView,
  },
  {
    path: "/organizations/create",
    name: "organizationCreate",
    component: OrganizationCreateView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: (...args: any[]) => void) => {
  if (to.name !== "login" && to.name !== "home" && store.getters.isAnonymous) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
