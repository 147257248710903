
import Vue from "vue";
import { Alert } from "@/commons/types";
import {
  DeviceHistorySchema,
  VehicleSchema,
  DeviceHistoriesService,
} from "@/commons/client";
import VehicleAssignmentDialog from "@/components/VehicleAssignmentDialog.vue";

export default Vue.extend({
  name: "DeviceHistoryList",

  components: {
    VehicleAssignmentDialog,
  },

  data: () => ({
    queryData: {
      perPage: 5,
      currentPage: 1,
      organization_id: 1,
    },
    loading: false,
    headers: [
      {
        text: "Seriale",
        align: "start",
        sortable: false,
        value: "current_device_id",
      },
      { text: "Veicolo", value: "current_vehicle_id" },
      { text: "Ultimo Giorno", value: "last_day_data" },
      { text: "Assegna veicolo", value: "assign_vehicle" },
    ],
  }),

  computed: {
    devices(): DeviceHistorySchema[] {
      // the computed is named devices because it's how we
      // present it in the view but the API is called device_histories
      return this.$store.state.deviceHistories;
    },
    vehicles(): VehicleSchema[] {
      return this.$store.state.vehicles;
    },
  },
});
