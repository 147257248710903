/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceAlarmSchema } from '../models/DeviceAlarmSchema';
import type { DeviceGpsSchema } from '../models/DeviceGpsSchema';
import type { DeviceStatusSchema } from '../models/DeviceStatusSchema';
import type { OrganizationCreateSchema } from '../models/OrganizationCreateSchema';
import type { OrganizationSchema } from '../models/OrganizationSchema';
import type { OrganizationUpdateSchema } from '../models/OrganizationUpdateSchema';
import type { OrganizationUserSchema } from '../models/OrganizationUserSchema';
import type { VehicleCreateSchema } from '../models/VehicleCreateSchema';
import type { VehicleSchema } from '../models/VehicleSchema';
import type { VehicleSetIdSchema } from '../models/VehicleSetIdSchema';
import type { VehicleUpdateSchema } from '../models/VehicleUpdateSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VehiclesService {

    /**
     * Organization List
     * @param skip
     * @param limit
     * @param search
     * @returns OrganizationSchema Successful Response
     * @throws ApiError
     */
    public static organizationListV1OrganizationsGet(
        skip?: number,
        limit: number = 100,
        search?: string,
    ): CancelablePromise<Array<OrganizationSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/',
            query: {
                'skip': skip,
                'limit': limit,
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Create
     * @param requestBody
     * @param ownerId
     * @returns OrganizationSchema Successful Response
     * @throws ApiError
     */
    public static organizationCreateV1OrganizationsPost(
        requestBody: OrganizationCreateSchema,
        ownerId?: number,
    ): CancelablePromise<OrganizationSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/organizations/',
            query: {
                'owner_id': ownerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Retrieve
     * @param pk
     * @returns OrganizationSchema Successful Response
     * @throws ApiError
     */
    public static organizationRetrieveV1OrganizationsPkGet(
        pk: number,
    ): CancelablePromise<OrganizationSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/{pk}/',
            path: {
                'pk': pk,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Update
     * @param pk
     * @param requestBody
     * @returns OrganizationSchema Successful Response
     * @throws ApiError
     */
    public static organizationUpdateV1OrganizationsPkPut(
        pk: number,
        requestBody: OrganizationUpdateSchema,
    ): CancelablePromise<OrganizationSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/organizations/{pk}/',
            path: {
                'pk': pk,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Delete
     * @param pk
     * @returns void
     * @throws ApiError
     */
    public static organizationDeleteV1OrganizationsPkDelete(
        pk: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/organizations/{pk}/',
            path: {
                'pk': pk,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Add Member
     * @param pk
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static organizationAddMemberV1OrganizationsPkAddMemberPatch(
        pk: number,
        requestBody: OrganizationUserSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/organizations/{pk}/add_member/',
            path: {
                'pk': pk,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Remove Member
     * @param pk
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static organizationRemoveMemberV1OrganizationsPkRemoveMemberPatch(
        pk: number,
        requestBody: OrganizationUserSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/organizations/{pk}/remove_member/',
            path: {
                'pk': pk,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Vehicles List
     * @param pk
     * @param skip
     * @param limit
     * @param search
     * @returns VehicleSchema Successful Response
     * @throws ApiError
     */
    public static organizationVehiclesListV1OrganizationsPkVehiclesGet(
        pk: number,
        skip?: number,
        limit: number = 100,
        search?: string,
    ): CancelablePromise<Array<VehicleSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/{pk}/vehicles/',
            path: {
                'pk': pk,
            },
            query: {
                'skip': skip,
                'limit': limit,
                'search': search,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Vehicles Create
     * @param pk
     * @param requestBody
     * @returns VehicleSchema Successful Response
     * @throws ApiError
     */
    public static organizationVehiclesCreateV1OrganizationsPkVehiclesPost(
        pk: number,
        requestBody: VehicleCreateSchema,
    ): CancelablePromise<VehicleSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/organizations/{pk}/vehicles/',
            path: {
                'pk': pk,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Vehicles Retrieve
     * @param pk
     * @param vehiclePk
     * @returns VehicleSchema Successful Response
     * @throws ApiError
     */
    public static organizationVehiclesRetrieveV1OrganizationsPkVehiclesVehiclePkGet(
        pk: number,
        vehiclePk: number,
    ): CancelablePromise<VehicleSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/{pk}/vehicles/{vehicle_pk}/',
            path: {
                'pk': pk,
                'vehicle_pk': vehiclePk,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Vehicles Update
     * @param pk
     * @param vehiclePk
     * @param requestBody
     * @returns VehicleSchema Successful Response
     * @throws ApiError
     */
    public static organizationVehiclesUpdateV1OrganizationsPkVehiclesVehiclePkPut(
        pk: number,
        vehiclePk: number,
        requestBody: VehicleUpdateSchema,
    ): CancelablePromise<VehicleSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/organizations/{pk}/vehicles/{vehicle_pk}/',
            path: {
                'pk': pk,
                'vehicle_pk': vehiclePk,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Vehicles Delete
     * @param pk
     * @param vehiclePk
     * @returns void
     * @throws ApiError
     */
    public static organizationVehiclesDeleteV1OrganizationsPkVehiclesVehiclePkDelete(
        pk: number,
        vehiclePk: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/organizations/{pk}/vehicles/{vehicle_pk}/',
            path: {
                'pk': pk,
                'vehicle_pk': vehiclePk,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Vehicles Set Vehicle Id
     * @param pk
     * @param vehiclePk
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static organizationVehiclesSetVehicleIdV1OrganizationsPkVehiclesVehiclePkSetVehicleIdPatch(
        pk: number,
        vehiclePk: number,
        requestBody: VehicleSetIdSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/organizations/{pk}/vehicles/{vehicle_pk}/set_vehicle_id/',
            path: {
                'pk': pk,
                'vehicle_pk': vehiclePk,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Vehicles Data Status
     * @param pk
     * @param vehicleId
     * @param start
     * @param stop
     * @param limit
     * @param offset
     * @returns DeviceStatusSchema Successful Response
     * @throws ApiError
     */
    public static organizationVehiclesDataStatusV1OrganizationsPkVehiclesVehicleIdDataStatusGet(
        pk: number,
        vehicleId: string,
        start: string = '1h',
        stop?: string,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<Array<DeviceStatusSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/{pk}/vehicles/{vehicle_id}/data/status/',
            path: {
                'pk': pk,
                'vehicle_id': vehicleId,
            },
            query: {
                'start': start,
                'stop': stop,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Vehicles Data Gps
     * @param pk
     * @param vehicleId
     * @param start
     * @param stop
     * @param limit
     * @param offset
     * @returns DeviceGpsSchema Successful Response
     * @throws ApiError
     */
    public static organizationVehiclesDataGpsV1OrganizationsPkVehiclesVehicleIdDataGpsGet(
        pk: number,
        vehicleId: string,
        start: string = '1h',
        stop?: string,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<Array<DeviceGpsSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/{pk}/vehicles/{vehicle_id}/data/gps/',
            path: {
                'pk': pk,
                'vehicle_id': vehicleId,
            },
            query: {
                'start': start,
                'stop': stop,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Organization Vehicles Data Alarms
     * @param pk
     * @param vehicleId
     * @param start
     * @param stop
     * @param limit
     * @param offset
     * @returns DeviceAlarmSchema Successful Response
     * @throws ApiError
     */
    public static organizationVehiclesDataAlarmsV1OrganizationsPkVehiclesVehicleIdDataAlarmsGet(
        pk: number,
        vehicleId: string,
        start: string = '1h',
        stop?: string,
        limit: number = 100,
        offset?: number,
    ): CancelablePromise<Array<DeviceAlarmSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/organizations/{pk}/vehicles/{vehicle_id}/data/alarms/',
            path: {
                'pk': pk,
                'vehicle_id': vehicleId,
            },
            query: {
                'start': start,
                'stop': stop,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
