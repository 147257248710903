/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceHistoryCreateSchema } from '../models/DeviceHistoryCreateSchema';
import type { DeviceHistorySchema } from '../models/DeviceHistorySchema';
import type { DeviceHistoryUpdateSchema } from '../models/DeviceHistoryUpdateSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DeviceHistoriesService {

    /**
     * Device History List
     * @param organizationId
     * @param skip
     * @param limit
     * @param noVehicleOnly
     * @returns DeviceHistorySchema Successful Response
     * @throws ApiError
     */
    public static deviceHistoryListV1DeviceHistoriesGet(
        organizationId: number,
        skip?: number,
        limit: number = 100,
        noVehicleOnly: boolean = false,
    ): CancelablePromise<Array<DeviceHistorySchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/device_histories/',
            query: {
                'skip': skip,
                'limit': limit,
                'organization_id': organizationId,
                'no_vehicle_only': noVehicleOnly,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Device History Create
     * @param requestBody
     * @returns DeviceHistorySchema Successful Response
     * @throws ApiError
     */
    public static deviceHistoryCreateV1DeviceHistoriesPost(
        requestBody: DeviceHistoryCreateSchema,
    ): CancelablePromise<DeviceHistorySchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/device_histories/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Device History Retrieve
     * @param pk
     * @param organizationId
     * @returns DeviceHistorySchema Successful Response
     * @throws ApiError
     */
    public static deviceHistoryRetrieveV1DeviceHistoriesPkGet(
        pk: string,
        organizationId: number,
    ): CancelablePromise<DeviceHistorySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/device_histories/{pk}/',
            path: {
                'pk': pk,
            },
            query: {
                'organization_id': organizationId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Device History Update
     * @param pk
     * @param organizationId
     * @param requestBody
     * @returns DeviceHistorySchema Successful Response
     * @throws ApiError
     */
    public static deviceHistoryUpdateV1DeviceHistoriesPkPut(
        pk: string,
        organizationId: number,
        requestBody: DeviceHistoryUpdateSchema,
    ): CancelablePromise<DeviceHistorySchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/device_histories/{pk}/',
            path: {
                'pk': pk,
            },
            query: {
                'organization_id': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Device History Delete
     * @param pk
     * @returns void
     * @throws ApiError
     */
    public static deviceHistoryDeleteV1DeviceHistoriesPkDelete(
        pk: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/device_histories/{pk}/',
            path: {
                'pk': pk,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Device History Retrieve By Mobo Serial
     * @param moboSerial
     * @returns DeviceHistorySchema Successful Response
     * @throws ApiError
     */
    public static deviceHistoryRetrieveByMoboSerialV1DeviceHistoriesByMoboSerialMoboSerialGet(
        moboSerial: string,
    ): CancelablePromise<DeviceHistorySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/device_histories/by_mobo_serial/{mobo_serial}/',
            path: {
                'mobo_serial': moboSerial,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
