
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "./Alerter.vue";
import {
  VehicleSchema,
  DeviceHistorySchema,
  DeviceHistoryUpdateSchema,
  DeviceHistoriesService,
} from "@/commons/client";
export default Vue.extend({
  name: "VehicleAssignmentDialog",

  props: {
    deviceHistory: {
      type: Object as () => DeviceHistorySchema,
      required: true,
    },
  },

  components: {
    Alerter,
  },

  data: () => ({
    dialog: false,
    assignedVehicleId: "",
    alerts: [] as Alert[],
  }),

  computed: {
    organizationId(): number {
      return this.$store.state.organizationId;
    },
    vehicles(): VehicleSchema[] {
      return this.$store.state.vehicles;
    },
    freeVehicles(): VehicleSchema[] {
      return this.vehicles.filter((v) => !this.isVehicleAssigned(v.vehicle_id)); // terrible performance (it iterates over all the deviceHistories every time)
    },
    deviceHistories(): DeviceHistorySchema[] {
      return this.$store.state.deviceHistories;
    },
    isValid(): boolean {
      return (
        this.assignedVehicleId !== "" &&
        this.assignedVehicleId !== null &&
        this.assignedVehicleId !== undefined &&
        this.assignedVehicleId !== this.deviceHistory.current_vehicle_id
      );
    },
    alerterClass() {
      return this.alerts.length > 0 ? "my-2" : "";
    },
  },

  methods: {
    getUpdateData(): DeviceHistoryUpdateSchema {
      return {
        ...this.deviceHistory,
        current_vehicle_id: this.assignedVehicleId,
      };
    },
    async updateDeviceHistory(): Promise<void> {
      if (!this.isValid) {
        this.alerts.push({
          type: "error",
          text: "Errore nella compilazione del form",
        });
        return;
      }
      try {
        let data = this.getUpdateData();
        const res =
          await DeviceHistoriesService.deviceHistoryUpdateV1DeviceHistoriesPkPut(
            this.deviceHistory.uid,
            this.organizationId,
            data
          );
        this.$store.commit("updateDeviceHistory", data);
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore nell'aggiornamento",
        });
      }
    },
    isVehicleAssigned(vehicleId: string): boolean {
      return this.deviceHistories.some(
        (dh) => dh.current_vehicle_id === vehicleId
      );
    },
  },
});
