
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "./Alerter.vue";
import {
  VehicleSchema,
  VehicleUpdateSchema,
  VehiclesService,
} from "@/commons/client";

export default Vue.extend({
  name: "VehicleDialog",

  props: {
    vehicle: {
      type: Object as () => VehicleSchema,
      required: true,
    },
  },

  components: {
    Alerter,
  },

  data: () => ({
    dialog: false,
    alerts: [] as Alert[],
    vehicleData: {} as VehicleUpdateSchema,
  }),

  computed: {
    organizationId(): number {
      return this.$store.state.organizationId;
    },
    rules() {
      return this.$store.state.genericRules;
    },
    alerterClass() {
      return this.alerts.length > 0 ? "my-2" : "";
    },
  },

  methods: {
    setData(data: VehicleSchema): void {
      this.vehicleData = {
        id: data.id,
        vehicle_id: data.vehicle_id,
        organization_id: data.organization_id,
        plate: data.plate,
      };
    },
    async updateVehicle(): Promise<void> {
      try {
        const res =
          await VehiclesService.organizationVehiclesUpdateV1OrganizationsPkVehiclesVehiclePkPut(
            this.vehicle.organization_id,
            this.vehicle.id,
            this.vehicleData
          );
        this.$store.commit("updateVehicle", res);
        this.dialog = false;
        this.setData(res);
      } catch (error) {
        this.alerts.push({
          type: "error",
          text: "Errore nell'aggiornamento del veicolo",
        });
      }
    },
  },

  created() {
    this.setData(this.vehicle);
  },
});
