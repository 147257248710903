
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "./Alerter.vue";
import {
  OrganizationSchema,
  OrganizationUpdateSchema,
  OrganizationsService,
} from "@/commons/client";

export default Vue.extend({
  name: "OrganizationCard",

  components: {
    Alerter,
  },

  data: () => ({
    organizationFormData: {} as OrganizationUpdateSchema,
    isUpdating: false,
    alerts: [] as Alert[],
  }),

  computed: {
    rules() {
      /**********************
       * Get rules from store
       **********************/
      return this.$store.state.genericRules;
    },
    countries() {
      return this.$store.state.countries;
    },
    alertsContainerClass() {
      return this.alerts.length > 0 ? "my-2" : "";
    },
    icon() {
      return !this.isUpdating ? "mdi-pencil" : "mdi-pencil-off";
    },
    maxCardWidth() {
      /*************************************
       * Return the right card length based on
       * current breakpoint.
       *************************************/
      switch (this.$vuetify.breakpoint.name) {
        case "xs" || "sm":
          return 400;
      }
      return 550;
    },
  },

  methods: {
    async updateOrganization() {
      /**********************
       * Update profile
       **********************/
      try {
        const res =
          await OrganizationsService.organizationUpdateV1OrganizationsPkPut(
            this.organizationId,
            this.organizationFormData
          );
        this.alerts.push({
          type: "success",
          text: "Dati aggiornati con successo!",
        });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante l'aggiornamento dei dati",
        });
      }
      this.isUpdating = false;
    },
    toggleUpdate() {
      /**********************
       * Toggle update
       **********************/
      this.isUpdating = !this.isUpdating;
    },
    async getOrganization(id: number): Promise<OrganizationSchema> {
      /**********************
       * Get organization
       **********************/
      try {
        return await OrganizationsService.organizationRetrieveV1OrganizationsPkGet(
          this.organizationId
        );
      } catch (err) {
        console.log(err);
        this.alerts.push({
          type: "error",
          text: "Errore nel recupero dei dati dell'azienda",
        });
        return {} as OrganizationSchema;
      }
    },
  },

  props: {
    organizationId: {
      type: Number,
      required: true,
    },
  },

  created() {
    /**********************
     * Get organization
     **********************/
    const organization = this.getOrganization(this.organizationId).then(
      (organization) => {
        this.organizationFormData = organization;
      }
    );
  },
});
