
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";
import PasswordChange from "@/components/PasswordChange.vue";
import OrganizationCard from "@/components/OrganizationCard.vue";
import { UserSchema, UserUpdateSchema, UsersService } from "@/commons/client";

export default Vue.extend({
  name: "Profile",

  components: {
    PasswordChange,
    OrganizationCard,
    Alerter,
  },

  data: () => ({
    userFormData: {} as UserUpdateSchema,
    isUpdatingProfile: false,
    alerts: [] as Alert[],
  }),

  computed: {
    userData(): UserSchema {
      return this.$store.state.user;
    },
    organizationId() {
      return this.$store.state.organizationId;
    },
    rules() {
      /**********************
       * Get rules from store
       **********************/
      return this.$store.state.genericRules;
    },
    countries() {
      return this.$store.state.countries;
    },
    icon() {
      return !this.isUpdatingProfile ? "mdi-pencil" : "mdi-pencil-off";
    },
    maxCardWidth() {
      /*************************************
       * Return the right card length based on
       * current breakpoint.
       *************************************/
      switch (this.$vuetify.breakpoint.name) {
        case "xs" || "sm":
          return 400;
      }
      return 550;
    },
  },

  methods: {
    setData(user: UserSchema): void {
      /*************************************
       * Set the data to the form
       *************************************/
      this.userFormData = {
        username: user.username,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
      };
    },
    setUserData(): void {
      /*************************************
       * Set the data to be displayed in the
       * form.
       *************************************/
      this.setData(this.userData);
    },
    async updateProfile(): Promise<void> {
      /**********************
       * Update profile
       **********************/
      try {
        const res = await UsersService.userUpdateV1UsersPkPut(
          this.userData.id,
          this.userFormData
        );
        this.setData(res);
        this.$store.commit("setUser", res);
        this.alerts.push({
          type: "success",
          text: "Profilo aggiornato con successo!",
        });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante l'aggiornamento del profilo",
        });
      }
      this.isUpdatingProfile = false;
    },
    toggleUpdate() {
      /**********************
       * Toggle update
       **********************/
      this.isUpdatingProfile = !this.isUpdatingProfile;
    },
  },

  created() {
    this.setUserData();
  },
});
