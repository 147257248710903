/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthTokenResponse } from '../models/AuthTokenResponse';
import type { Body_login_v1_auth_token__post } from '../models/Body_login_v1_auth_token__post';
import type { UserSchema } from '../models/UserSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Login
     * @param formData
     * @returns AuthTokenResponse Successful Response
     * @throws ApiError
     */
    public static loginV1AuthTokenPost(
        formData: Body_login_v1_auth_token__post,
    ): CancelablePromise<AuthTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/token/',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Request User
     * @returns UserSchema Successful Response
     * @throws ApiError
     */
    public static requestUserV1AuthUserGet(): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/auth/user/',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
