
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "./Alerter.vue";
import { VehicleCreateSchema, VehiclesService } from "@/commons/client";

export default Vue.extend({
  name: "CreateVehicleDialog",

  components: {
    Alerter,
  },

  data: () => ({
    dialog: false,
    vehicleData: {} as VehicleCreateSchema,
    alerts: [] as Alert[],
  }),

  computed: {
    rules() {
      return this.$store.state.genericRules;
    },
    organizationId(): number {
      return this.$store.state.organizationId;
    },
    alerterClass() {
      return this.alerts.length > 0 ? "my-2" : "";
    },
  },

  methods: {
    async createVehicle(): Promise<void> {
      try {
        const res =
          await VehiclesService.organizationVehiclesCreateV1OrganizationsPkVehiclesPost(
            this.organizationId,
            this.vehicleData
          );
        this.$store.commit("pushVehicle", res);
        this.alerts.push({
          type: "success",
          text: "Veicolo creato con successo",
        });
      } catch (err) {
        this.alerts.push({
          type: "error",
          text: "Errore durante la creazione del veicolo",
        });
      }
      this.dialog = false;
    },
  },
});
