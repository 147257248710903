
import Vue from "vue";
import NavigationMenu from "./NavigationMenu.vue";

export default Vue.extend({
  name: "Navbar",
  components: {
    NavigationMenu,
  },
  data: () => ({
    showDrawer: false,
  }),
  computed: {
    isAuthenticated() {
      return !this.$store.getters.isAnonymous;
    },
  },
  methods: {
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "login" });
    },
  },
});
