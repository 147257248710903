
import Vue from "vue";

export default Vue.extend({
  name: "Footer",

  computed: {
    isMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
});
