/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserCreateSchema } from '../models/UserCreateSchema';
import type { UserOrganizationIdSchema } from '../models/UserOrganizationIdSchema';
import type { UserSchema } from '../models/UserSchema';
import type { UserSetPasswordSchema } from '../models/UserSetPasswordSchema';
import type { UserUpdateSchema } from '../models/UserUpdateSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * User List
     * @param skip
     * @param limit
     * @param username
     * @param email
     * @returns UserSchema Successful Response
     * @throws ApiError
     */
    public static userListV1UsersGet(
        skip?: number,
        limit: number = 100,
        username?: string,
        email?: string,
    ): CancelablePromise<Array<UserSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/',
            query: {
                'skip': skip,
                'limit': limit,
                'username': username,
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Create
     * @param requestBody
     * @returns UserSchema Successful Response
     * @throws ApiError
     */
    public static userCreateV1UsersPost(
        requestBody: UserCreateSchema,
    ): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Retrieve
     * @param pk
     * @returns UserSchema Successful Response
     * @throws ApiError
     */
    public static userRetrieveV1UsersPkGet(
        pk: number,
    ): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/{pk}/',
            path: {
                'pk': pk,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Update
     * @param pk
     * @param requestBody
     * @returns UserSchema Successful Response
     * @throws ApiError
     */
    public static userUpdateV1UsersPkPut(
        pk: number,
        requestBody: UserUpdateSchema,
    ): CancelablePromise<UserSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/users/{pk}/',
            path: {
                'pk': pk,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Destroy
     * @param pk
     * @returns void
     * @throws ApiError
     */
    public static userDestroyV1UsersPkDelete(
        pk: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/users/{pk}/',
            path: {
                'pk': pk,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Set Password
     * @param pk
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static userSetPasswordV1UsersPkSetPasswordPatch(
        pk: number,
        requestBody: UserSetPasswordSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/users/{pk}/set_password/',
            path: {
                'pk': pk,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Change Password
     * @param pk
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static userChangePasswordV1UsersPkChangePasswordPatch(
        pk: number,
        requestBody: UserSetPasswordSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/users/{pk}/change_password/',
            path: {
                'pk': pk,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * User Organization Id
     * @returns UserOrganizationIdSchema Successful Response
     * @throws ApiError
     */
    public static userOrganizationIdV1UsersCurrentOrganizationIdGet(): CancelablePromise<UserOrganizationIdSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/current/organization_id/',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
